import { createTheme, responsiveFontSizes } from '@mui/material'
import type { Theme } from '@mui/material'
import { breakpoints, palette, getBorders, getElevation, getTypography, getButton } from './themePieces'

const getTheme = (theme: Theme) => {
  let newTheme = createTheme(theme, {
    typography: getTypography(theme),
    borders: getBorders(theme),
    elevation: getElevation(theme),
  })

  newTheme = createTheme(newTheme, {
    components: {
      MuiButton: getButton(newTheme),
    },
  })

  return newTheme
}

//* ---------- DEFAULT ----------
let defaultTheme = createTheme({
  palette,
  typography: {
    fontFamily: ['Montserrat', 'Arial', 'sans-serif', '-apple-system'].join(','),
    fontSize: 16,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  spacing: (factor: number) => `${0.5 * factor}rem`,
  breakpoints,
})

defaultTheme = getTheme(defaultTheme)
defaultTheme = responsiveFontSizes(defaultTheme)

//* ---------- SKIFFLE ----------
let skiffleTheme = createTheme(defaultTheme, {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: '#323456',
    },
    background: {
      // default: '#f6f7fb',
    },
  },
})

skiffleTheme = getTheme(skiffleTheme)
skiffleTheme = responsiveFontSizes(skiffleTheme)

//* ---------- ADMIN ----------
// let adminTheme = createTheme(defaultTheme, {
//   ...defaultTheme
// })

export { defaultTheme, skiffleTheme }
