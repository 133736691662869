import { BottomNavigation as MuiBottomNav, BottomNavigationAction as MuiBottomNavAction, styled } from '@mui/material'
import type { BottomNavigationActionProps, BottomNavigationProps } from '@mui/material'

const BottomNavigation = styled((props: BottomNavigationProps) => <MuiBottomNav showLabels {...props} />)(
  ({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: 72,
    borderTop: theme.borders.n100,
    zIndex: theme.zIndex.modal,
    '& .MuiBottomNavigationAction-root.Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  }),
)

const BottomNavigationAction = styled((props: BottomNavigationActionProps) => <MuiBottomNavAction {...props} />)(
  ({ theme }) => ({
    ...theme.typography.tab,
    '& .MuiBottomNavigationAction-label': { pt: 1 },
  }),
)

export { BottomNavigation, BottomNavigationAction }
