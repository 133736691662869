import { Suspense } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Stack, Typography } from '@mui/material'
import { Fallback, Routes } from 'components'
import { useGlobalState } from 'utils'

function App() {
  const { user, onSkiffle } = useGlobalState()
  const navigate = useNavigate()

  if (user?.is_active) {
    return (
      <Suspense fallback={<Fallback />}>
        <Routes />
      </Suspense>
    )
  }

  return (
    <Stack spacing={4} sx={{ p: 4 }}>
      <Typography variant='h1'>You are not authorized to use this application.</Typography>
      <Typography variant='body1'>If you are seeing this in error, please contact Trinity Apparel Support.</Typography>
      <Button
        color='neutral'
        onClick={() => (onSkiffle ? window?.ReactNativeWebView?.postMessage('logout') : navigate(-1))}
        sx={{ width: { xs: 1, md: 1 / 5 } }}
      >
        Go Back
      </Button>
    </Stack>
  )
}

export default App
