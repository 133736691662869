import { Chip, styled } from '@mui/material'
import {
  DELIVERY_STATUSES,
  STATUS_BLUE_PENCIL,
  STATUS_CMT_FABRIC_HOLD,
  STATUS_CUTTING,
  STATUS_FABRIC_HOLD,
  STATUS_FINAL_INSPECTION,
  STATUS_INTERNATIONAL_TRANSIT,
  STATUS_PRODUCTION,
  STATUS_PRODUCTION_COMPLETE,
  STATUS_READY,
  STATUS_SHIPMENT_PROCESSING,
} from 'utils'
import type { ChipProps } from '@mui/material'
import type { OrderStatus } from 'types'

interface StyledChipProps extends ChipProps {
  status: OrderStatus
}

const OrderStatusChip = styled(({ status, ...props }: StyledChipProps) => {
  if (!(DELIVERY_STATUSES as ReadonlyArray<string>).includes(status.description)) {
    const color = getStatusColor(status.description as ValidStatuses)
    return <Chip color={color} label={status.description} {...props} />
  }

  return <Chip sx={{ backgroundColor: 'grey.75', color: 'grey.500' }} label={status.description} {...props} />
})(({ theme }) => ({
  height: 26,
  borderRadius: 13,
  fontSize: theme.spacing(1.75),
  fontWeight: theme.typography.fontWeightRegular,
}))

export { OrderStatusChip }

type ValidStatuses =
  | typeof STATUS_READY
  | typeof STATUS_FABRIC_HOLD
  | typeof STATUS_CMT_FABRIC_HOLD
  | typeof STATUS_BLUE_PENCIL
  | typeof STATUS_CUTTING
  | typeof STATUS_PRODUCTION
  | typeof STATUS_PRODUCTION_COMPLETE
  | typeof STATUS_BLUE_PENCIL
  | typeof STATUS_INTERNATIONAL_TRANSIT
  | typeof STATUS_FINAL_INSPECTION
  | typeof STATUS_SHIPMENT_PROCESSING

type ValidColors = 'warning' | 'error' | 'info' | 'neutral'

function getStatusColor(status: ValidStatuses): ValidColors {
  switch (status) {
    case STATUS_READY:
      return 'warning'
    case STATUS_FABRIC_HOLD:
    case STATUS_CMT_FABRIC_HOLD:
      return 'error'
    case STATUS_BLUE_PENCIL:
    case STATUS_CUTTING:
    case STATUS_PRODUCTION:
    case STATUS_PRODUCTION_COMPLETE:
      return 'info'
    case STATUS_INTERNATIONAL_TRANSIT:
    case STATUS_FINAL_INSPECTION:
    case STATUS_SHIPMENT_PROCESSING:
      return 'neutral'
  }
}
