import { CircularProgress, IconButton, InputAdornment, OutlinedInput, styled } from '@mui/material'
import { Close, Search as MuiSearch } from '@mui/icons-material'
import type { OutlinedInputProps } from '@mui/material'

interface SearchProps extends OutlinedInputProps {
  isLoading: boolean
  handleClear: () => void
  innerRef?: React.MutableRefObject<HTMLElement | null>
}

const Search = styled(({ isLoading, handleClear, innerRef, ...props }: SearchProps) => (
  <OutlinedInput
    {...props}
    ref={innerRef}
    endAdornment={
      <InputAdornment position='end' disablePointerEvents={!props.value}>
        <IconButton size='small' onClick={handleClear}>
          {isLoading ? (
            <CircularProgress color='inherit' size={20} />
          ) : props.value ? (
            <Close color='disabled' />
          ) : (
            <MuiSearch color='primary' />
          )}
        </IconButton>
      </InputAdornment>
    }
  />
))(({ theme }) => ({
  height: 43,
  [theme.breakpoints.down('md')]: {
    height: 51,
  },
  '& .MuiOutlinedInput-input': {
    ...theme.typography.smallBody1,
    marginLeft: theme.spacing(1),
    color: theme.palette.common.black,
  },
}))

export { Search }
