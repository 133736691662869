import { useQuery } from 'react-query'
import { BUTTONS, FELT, LABELS, PIPING, SUEDE, THREADS, TROUSER_TRIM, ZIPPERS } from 'utils/constants'
import type {
  Button,
  Felt,
  Material,
  NameLabel,
  PaginationParams,
  Piping,
  Suede,
  Thread,
  TrouserTrim,
  Zippers,
} from 'types'
import API from './api'

// * GET materials
interface MaterialsParams extends PaginationParams {
  garment_type?: string | number
}

const API_ROUTES = {
  [BUTTONS.id]: 'buttons',
  [FELT.id]: 'felts',
  [SUEDE.id]: 'suedes',
  [PIPING.id]: 'material_pipings',
  [LABELS.id]: 'labels',
  [THREADS.id]: 'material_threads',
  [TROUSER_TRIM.id]: 'material_trouser_trims',
  [ZIPPERS.id]: 'zippers',
}

async function getMaterials(params: MaterialsParams, materialType: string): Promise<Material[]> {
  try {
    const route = API_ROUTES[materialType]
    if (route) {
      const { data } = await API.get(API_ROUTES[materialType], { params })

      //Remove any materials without images
      const filteredData = data.filter((material: MaterialType) => material.image)

      const materials = transformData(materialType, filteredData)

      return materials
    } else {
      return Promise.reject(new Error('Invalid material type'))
    }
  } catch {
    return Promise.reject(new Error('There was an error - please try again.'))
  }
}

function useMaterials(materialType: string, garment_type?: number) {
  let params: MaterialsParams = { page: '1', per_page: '1000' }
  if (garment_type) params = { ...params, garment_type }

  return useQuery([materialType, params], () => getMaterials(params, materialType))
}

export { useMaterials }

//* HELPERS

type MaterialType = Button | Felt | Suede | Piping | NameLabel | Thread | TrouserTrim | Zippers

function transformData(materialType: string, data: MaterialType[]): Material[] {
  switch (materialType) {
    case BUTTONS.id:
    case FELT.id:
    case SUEDE.id:
      if (!isButton(data)) break
      return data.map(({ value: id, code, title: description, image }) => ({
        id,
        code,
        description,
        image,
      }))
    case PIPING.id:
      if (!isPiping(data)) break
      return data.map(({ id, trinity_fabric_number: code, description, image }) => ({
        id,
        code,
        description,
        image,
      }))
    case LABELS.id:
      if (!isNameLabel(data)) break
      return data.map(({ value: id, description: code, image }) => ({ id, code, description: '', image }))
    case THREADS.id:
      if (!isThread(data)) break
      return data.map(({ value: id, code, description, image }) => ({ id, code, description, image }))
    case TROUSER_TRIM.id:
    case ZIPPERS.id:
      if (!isTrouserTrim(data)) break
      return data.map(({ id, description: code, image }) => ({ id, code, description: '', image }))
  }
  throw new Error('Invalid material type')
}

const isButton = (x: MaterialType[]): x is Button[] => Object.prototype.hasOwnProperty.call(x[0], 'value')
const isPiping = (x: MaterialType[]): x is Piping[] => Object.prototype.hasOwnProperty.call(x[0], 'id')
const isNameLabel = (x: MaterialType[]): x is NameLabel[] => !Object.prototype.hasOwnProperty.call(x[0], 'code')
const isThread = (x: MaterialType[]): x is Thread[] => !Object.prototype.hasOwnProperty.call(x[0], 'title')
const isTrouserTrim = (x: MaterialType[]): x is TrouserTrim[] =>
  Object.prototype.hasOwnProperty.call(x[0], 'created_at')
