import type { Theme } from '@mui/material'

export const getTypography = (theme: Theme) => ({
  h1: {
    fontSize: '2.75rem',
    fontWeight: 600,
    color: theme.palette.grey[900],
  },
  h2: {
    fontSize: '2.25rem',
    fontWeight: 600,
    color: theme.palette.grey[900],
  },
  h3: {
    fontSize: '2rem',
    fontWeight: 600,
    color: theme.palette.grey[900],
  },
  h4: {
    fontSize: '1.75rem',
    fontWeight: 600,
    color: theme.palette.grey[900],
  },
  h5: {
    fontSize: '1.25rem',
    fontWeight: 600,
    color: theme.palette.grey[900],
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 700,
    color: theme.palette.grey[900],
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: 500,
    color: theme.palette.grey[300],
    lineHeight: 1.5,
  },
  body1: {
    fontSize: '1rem',
    fontWeight: 500,
    color: theme.palette.grey[500],
    lineHeight: 1.5,
  },
  body2: {
    fontSize: '1rem',
    fontWeight: 500,
    color: theme.palette.grey[300],
    lineHeight: 1.5,
  },
  button: {
    fontSize: '1rem',
    fontWeight: 600,
    color: theme.palette.common.white,
    lineHeight: 1,
  },
  //Custom Variants
  htable: {
    fontSize: '0.75rem',
    fontWeight: 700,
    color: theme.palette.grey[700],
  },
  body3: {
    fontSize: '1rem',
    fontWeight: 500,
    color: theme.palette.grey[700],
    lineHeight: 1.5,
  },
  smallBody1: {
    fontSize: '0.875rem',
    fontWeight: 500,
    color: theme.palette.grey[500],
    lineHeight: 1.25,
  },
  smallBody2: {
    fontSize: '0.875rem',
    fontWeight: 500,
    color: theme.palette.grey[300],
    lineHeight: 1.25,
  },
  smallBody3: {
    fontSize: '0.875rem',
    fontWeight: 500,
    color: theme.palette.grey[700],
    lineHeight: 1.25,
  },
  link: {
    fontSize: '1rem',
    fontWeight: 600,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  smallLink: {
    fontSize: '0.875rem',
    fontWeight: 500,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  smallButton: {
    fontSize: '0.875rem',
    fontWeight: 600,
    color: theme.palette.common.white,
    lineHeight: 0.875,
  },
  largeButton: {
    fontSize: '1rem',
    fontWeight: 700,
    color: theme.palette.common.white,
    lineHeight: 1,
  },
  nav1: {
    fontSize: '0.8125rem',
    fontWeight: 600,
    color: theme.palette.grey[500],
    letterSpacing: 2,
  },
  nav2: {
    fontSize: '1rem',
    fontWeight: 600,
    color: theme.palette.grey[500],
    letterSpacing: 2,
  },
  tab: {
    fontSize: '1rem',
    fontWeight: 600,
    color: theme.palette.grey[300],
    letterSpacing: 0,
  },
  label: {
    fontSize: '0.75rem',
    fontWeight: 500,
    color: theme.palette.common.white,
    lineHeight: 2,
  },
})

declare module '@mui/material/styles' {
  interface TypographyVariants {
    htable: React.CSSProperties
    body3: React.CSSProperties
    smallBody1: React.CSSProperties
    smallBody2: React.CSSProperties
    smallBody3: React.CSSProperties
    link: React.CSSProperties
    smallLink: React.CSSProperties
    smallButton: React.CSSProperties
    largeButton: React.CSSProperties
    nav1: React.CSSProperties
    nav2: React.CSSProperties
    tab: React.CSSProperties
    label: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    htable?: React.CSSProperties
    body3?: React.CSSProperties
    smallBody1?: React.CSSProperties
    smallBody2?: React.CSSProperties
    smallBody3?: React.CSSProperties
    link?: React.CSSProperties
    smallLink?: React.CSSProperties
    smallButton?: React.CSSProperties
    largeButton?: React.CSSProperties
    nav1?: React.CSSProperties
    nav2?: React.CSSProperties
    tab?: React.CSSProperties
    label?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    htable: true
    body3: true
    smallBody1: true
    smallBody2: true
    smallBody3: true
    link: true
    smallLink: true
    smallButton: true
    largeButton: true
    nav1: true
    nav2: true
    tab: true
    label: true
  }
}
