import React from 'react'
import { Navigate, Outlet, Route, Routes as ReactRouterRoutes } from 'react-router-dom'
// import * as Sentry from '@sentry/react'
import { Box, CircularProgress, styled } from '@mui/material'
import { useGlobalState } from 'utils'

const AdminRoutes = React.lazy(() => import('./AdminRoutes'))
const DealerRoutes = React.lazy(() => import('./DealerRoutes'))
const SkiffleRoutes = React.lazy(() => import('./SkiffleRoutes'))

//* There is currently a bug with using SentryRoutes that is causing entire component unmount/remounts on state changes
//* Needs more investigation on why this is happening, so just commenting it out for now

function Routes() {
  // const SentryRoutes = Sentry.withSentryReactRouterV6Routing(ReactRouterRoutes)
  const { user } = useGlobalState()

  return (
    <ReactRouterRoutes>
      <Route path='/' element={<Outlet />}>
        <Route index element={<Navigate replace to='dealer' />} />

        {/* // * ADMIN * // */}
        {user?.roles.admin && <Route path='/admin/*' element={<AdminRoutes />} />}

        {/* // * DEALER * // */}
        {user?.roles.dealer && <Route path='/dealer/*' element={<DealerRoutes />} />}

        {/* // * SKIFFLE * // */}
        <Route path='/skiffle/*' element={<SkiffleRoutes />} />

        {/* // * NOT FOUND * // */}
        <Route path='*' element={<h1>Page Not Found</h1>} />
      </Route>
    </ReactRouterRoutes>
  )
}

export default Routes
export { GlobalContainer, Fallback }

const GlobalContainer = styled('div')(({ theme }) => ({
  maxWidth: '1440px',
  margin: '0 auto',
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(6),
  },
  // [theme.breakpoints.up('xl')]: {
  //   padding: theme.spacing(8),
  // },
}))

function Fallback() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', pt: 20 }}>
      <CircularProgress size={120} />
    </Box>
  )
}
