import { createContext, useState, useEffect, useMemo } from 'react'
import Cookies from 'js-cookie'
import { Box, CircularProgress } from '@mui/material'
import { loginUrl, useLogin } from 'utils'
import type { Login } from 'types'

interface AuthContextValue {
  user: Login | undefined
  token: string
}

const AuthContext = createContext<AuthContextValue | null>(null)
AuthContext.displayName = 'AuthContext'

interface AuthProviderProps {
  children: React.ReactNode
}

function AuthProvider({ children }: AuthProviderProps) {
  const [token, setToken] = useState('')
  const loginQuery = useLogin()
  const user = useMemo(() => ({ user: loginQuery.data, token }), [loginQuery.data, token])

  useEffect(() => {
    const cookieJwt = Cookies.get('jwt')
    const storageJwt = window.localStorage.getItem('jwt')
    const jwt = cookieJwt ?? storageJwt

    if (jwt) {
      sessionStorage.setItem('jwt', jwt)
      setToken(jwt)
    } else {
      window.location.replace(loginUrl)
    }
  }, [])

  if (loginQuery.isLoading || loginQuery.isIdle) {
    return (
      <Box sx={{ display: 'flex', width: 1, height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress size={80} />
      </Box>
    )
  }

  if (loginQuery.isError) {
    return <h1>There was an error - please refresh the page.</h1>
  }

  if (loginQuery.isSuccess) {
    return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>
  }

  throw new Error('Unhandled Status')
}

export { AuthProvider, AuthContext }
