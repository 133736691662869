import { createContext, useMemo } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { useLocation } from 'react-router-dom'

interface GlobalContextValue {
  onMobile: boolean
  onTablet: boolean
  onSkiffle: boolean
}

interface GlobalProviderProps {
  children: React.ReactNode
}

const GlobalContext = createContext<GlobalContextValue | null>(null)
GlobalContext.displayName = 'GlobalContext'

function GlobalProvider({ children }: GlobalProviderProps) {
  const { pathname } = useLocation()
  const onSkiffle = pathname.startsWith('/skiffle')
  const theme = useTheme()
  const onMobile = useMediaQuery(theme.breakpoints.down('md'))
  const onTablet = useMediaQuery(theme.breakpoints.down('lg'))
  const value = useMemo(() => ({ onMobile, onTablet, onSkiffle }), [onMobile, onSkiffle, onTablet])

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
}

export { GlobalProvider, GlobalContext }
