import { BrowserRouter as Router } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { IntercomProvider } from 'react-use-intercom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { defaultTheme } from 'styles/theme'
import { AuthProvider } from './auth'
import { GlobalProvider } from './global'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
})

const INTERCOM_ID = 'kasssq3w'

interface AppProviderProps {
  children: React.ReactNode
}

function AppProviders({ children }: AppProviderProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <Router basename='/app'>
          <ThemeProvider theme={defaultTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CssBaseline />
              <AuthProvider>
                <IntercomProvider appId={INTERCOM_ID}>
                  <GlobalProvider>{children}</GlobalProvider>
                </IntercomProvider>
              </AuthProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </Router>
      </Sentry.ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default AppProviders

interface ErrorFallbackProps {
  error: Error
  resetError: () => void
}

function ErrorFallback({ error, resetError }: ErrorFallbackProps) {
  return (
    <div style={{ padding: '10rem' }}>
      <h1>You have encountered an error:</h1>
      <h5>{error.toString()}</h5>
      <button onClick={resetError}>Try Again</button>
    </div>
  )
}
