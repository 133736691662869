import type { BreakpointsOptions, Theme } from '@mui/material'

const getBorders = (theme: Theme) => ({
  success: `1px solid ${theme.palette.success.main}`,
  primary: `1px solid ${theme.palette.primary.main}`,
  n100: `1px solid ${theme.palette.grey[100]}`,
  n300: `1px solid ${theme.palette.grey[300]}`,
  n500: `1px solid ${theme.palette.grey[500]}`,
  n700: `1px solid ${theme.palette.grey[700]}`,
  selected: `2px solid ${theme.palette.primary.main}`,
  active: `4px solid ${theme.palette.grey[700]}`,
  error: `1px solid ${theme.palette.error.main}`,
})

const getElevation = (theme: Theme) => ({
  autocomplete: '0 4px 7px 0 rgba(20,20,43,0.12)',
  accordian: `0 22px 39px -25px ${theme.palette.grey[900]}`,
  search: '0 20px 30px -20px rgba(50,50,91,0.5)',
  card: '0 10px 25px 0 rgba(50,50,91,0.2)',
  card2: '0 4px 15px 0 rgba(20,20,43,0.15)',
  mobile: '0 -15px 20px -20px rgba(50,50,91,0.5)',
  divider: `inset 0 -1px 0 0 ${theme.palette.grey[100]}`,
  avatar: '0 10px 40px 0 rgba(50,50,91,0.4)',
  button: '0 4px 8px 0 rgba(50,50,91,0.2)',
  fabricImage: 'inset 0 -40px 30px 0 rgba(0,0,0,0.4)',
  selectedButton: '0px 3px 10px rgba(0, 0, 0, 0.05)',
})

const breakpoints: BreakpointsOptions = {
  // keys: ['mobile', 'tablet', 'laptop', 'desktop'],
  values: {
    xs: 0, // mobile
    sm: 640, // tablet
    md: 1024, // laptop
    lg: 1280, // desktop
    xl: 1920,
    // mobile: 0,
    // tablet: 640,
    // laptop: 1024,
    // desktop: 1280,
  },
}

declare module '@mui/material/styles' {
  interface Theme {
    borders: {
      success: string
      primary: string
      n100: string
      n300: string
      n500: string
      n700: string
      selected: string
      active: string
      error: string
    }
    elevation: {
      autocomplete: string
      accordian: string
      search: string
      card: string
      card2: string
      mobile: string
      divider: string
      avatar: string
      button: string
      fabricImage: string
      selectedButton: string
    }
  }

  interface ThemeOptions {
    borders?: {
      success?: string
      primary?: string
      n100?: string
      n300?: string
      n500?: string
      n700?: string
      selected?: string
      active?: string
      error?: string
    }
    elevation?: {
      autocomplete?: string
      accordian?: string
      search?: string
      card?: string
      card2?: string
      mobile?: string
      divider?: string
      avatar?: string
      button?: string
      fabricImage?: string
      selectedButton?: string
    }
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    neutral: true
  }
}

export { getBorders, getElevation, breakpoints }
