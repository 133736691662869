import {
  ButtonImage,
  CasualJacketImage,
  FeltImage,
  JacketImage,
  NameLabelImage,
  OuterwearImage,
  PantImage,
  PipingImage,
  ShirtImage,
  SuedeImage,
  ThreadImage,
  TrouserTrimImage,
  VestImage,
  ZipperImage,
} from 'assets/images'

//* IMPORTANT URLS
export const WORKFLOW = import.meta.env.VITE_PHP_URL
export const API_URL = import.meta.env.VITE_API_URL
export const DOCUMENTS = import.meta.env.VITE_DOCUMENTS_URL
export const AWS_URL = 'https://trinity-apparel.s3.us-east-2.amazonaws.com'

// AuthProvider
export const loginUrl = `${WORKFLOW}login.php`

// Clients
export const downloadClientCSV = (token: string) => `${API_URL}customers/dashboard.csv?token=${token}`
export const downloadClientInsightCSV = (token: string, params: string) => `${downloadClientCSV(token)}&${params}`

// Client
export const clientMeasurementsUrl = (id: number) =>
  `${WORKFLOW}dealer/customer_manager.php?cust_action=customer_measurements&customer_id=${id}`
export const clientImagesUrl = (id: number) =>
  `${WORKFLOW}dealer/customer_manager.php?cust_action=customer_images&customer_id=${id}`

// Fabric Explorerer
export const downloadFabricStatus = (collectionId: number | 'favorites', dealerId?: number) =>
  `${DOCUMENTS}/collections/${collectionId}/fabric_status?dealer_id=${dealerId}`
export const downloadFabricImage = (id: number, token: string, fabricNumber: string, url: string) =>
  `${API_URL}fabrics/${id}/image?token=${token}&filename=${fabricNumber}&url=${encodeURIComponent(url)}`

// Order Status
export const continueOrderUrl = (id: number) =>
  `${WORKFLOW}dealer/order_manager.php?order_action=order_items&dealer_order_id=${id}`
export const viewOrderUrl = (id: number) => `${WORKFLOW}share/order_status.php?dealer_order_id=${id}`
export const editOrderUrl = (orderId: number, garmentId: number) =>
  `${continueOrderUrl(orderId)}&trinity_review=1&dealer_edit=1&suit_id=${garmentId}`

// New Order
export const startOrderUrl = (id: number, orderType: string) =>
  `${WORKFLOW}dealer/order_manager.php?order_action=order_create&order_type=${orderType}&customer_id=${id}`

// NavLinks - Workflow
export const orderPartsUrl = 'https://form.jotform.com/90624009784157'
export const searchOrdersUrl = `${WORKFLOW}dealer/order_status.php?order_action=order_status`
export const incompleteOrdersUrl = `${WORKFLOW}dealer/incomplete_orders.php`
// export const priceListUrl = `${WORKFLOW}dealer/fabric_prices.php`
export const universityUrl = `${WORKFLOW}dealer/documents.php`
export const helpCenterUrl = 'http://help.trinity-apparel.com/'
export const measurementsGuideUrl = `${WORKFLOW}learn/msmt_guide/Measurements.html`
export const returnProgramUrl = `${WORKFLOW}dealer/40_40_Program.php?action=search`
export const policiesUrl = `${WORKFLOW}dealer/privacy.php?privacy=brief`
export const studioUrl = `${WORKFLOW}studio/`
export const digitalShowcaseUrl = 'https://shopdigitalcustom.com/'
export const accountInfoUrl = `${WORKFLOW}dealer/dealer_manager.php?dealer_action=details`
export const customModelsUrl = `${WORKFLOW}share/models.php?model_action=custom_models`
export const optionModelVisibilityUrl = `${WORKFLOW}share/models.php?model_action=visibility`
export const shippingHoldUrl = `${WORKFLOW}dealer/dealer_manager.php?dealer_action=hold_orders`
export const changePasswordUrl = `${WORKFLOW}login.php?login_action=update_pw`
export const trendReportUrl = `${WORKFLOW}share/dealer_trend.php`
export const discountsUrl = `${WORKFLOW}dealer/discounts.php`
export const manageCreditCardsUrl = `${WORKFLOW}dealer/payment_manager.php?payment_action=list_cards`
export const paymentHistoryUrl = `${WORKFLOW}dealer/payment_history.php`
export const paymentErrorsUrl = `${WORKFLOW}dealer/payment_manager.php?payment_action=payment_resolution`
export const invoicesUrl = `${WORKFLOW}dealer/invoices.php`
export const switchToTrinityUrl = `${WORKFLOW}trinity/`
export const switchToAgentUrl = `${WORKFLOW}agent/`
export const logoutUrl = `${WORKFLOW}login.php?login_action=logout`

//* ORDER STATUSES
export const STATUS_INCOMPLETE = 'Incomplete'
export const STATUS_CMT_FABRIC_HOLD = 'CMT Fabric Hold'
export const STATUS_FABRIC_HOLD = 'Fabric Hold'
export const STATUS_READY = 'Ready'
export const STATUS_BLUE_PENCIL = 'Blue Pencil'
export const STATUS_CUTTING = 'Cutting'
export const STATUS_PRODUCTION = 'Production'
export const STATUS_PRODUCTION_COMPLETE = 'Production Complete'
export const STATUS_INTERNATIONAL_TRANSIT = 'International Transit'
export const STATUS_FINAL_INSPECTION = 'Final Inspection'
export const STATUS_SHIPMENT_PROCESSING = 'Shipment Processing'
export const STATUS_DELIVERY = 'Delivery'
export const STATUS_DIRECT_SHIP = 'Direct Ship'
export const STATUS_CANCELLED = 'Cancelled'

export const EDITABLE_STATUSES = [STATUS_FABRIC_HOLD, STATUS_CMT_FABRIC_HOLD, STATUS_READY] as const
export const DELIVERY_STATUSES = [STATUS_DELIVERY, STATUS_DIRECT_SHIP] as const

//* GARMENT TYPES
export const SHIRT = {
  abbreviation: 'CSHT',
  formalName: 'Shirt',
  alternateName: null,
  bitmask: 8,
  image: ShirtImage,
} as const
export const COAT = {
  abbreviation: 'CSC',
  formalName: 'Jacket',
  alternateName: null,
  bitmask: 1,
  image: JacketImage,
} as const
export const VEST = {
  abbreviation: 'CV',
  formalName: 'Vest',
  alternateName: null,
  bitmask: 2,
  image: VestImage,
} as const
export const PANT = {
  abbreviation: 'CT',
  formalName: 'Pant',
  alternateName: null,
  bitmask: 4,
  image: PantImage,
} as const
export const TOPCOAT = {
  abbreviation: 'CTOPC',
  formalName: 'Outerwear',
  alternateName: null,
  bitmask: 32,
  image: OuterwearImage,
} as const
export const SHORT = {
  abbreviation: 'CSHO',
  formalName: 'Short',
  alternateName: null,
  bitmask: 64,
  image: null,
} as const
export const SWACKET = {
  abbreviation: 'SWK',
  formalName: 'Swacket',
  alternateName: null,
  bitmask: 512,
  image: null,
} as const
export const BREEK = {
  abbreviation: 'CBK',
  formalName: 'Breek',
  alternateName: null,
  bitmask: 128,
  image: null,
} as const
export const COAT_PANT = {
  abbreviation: 'CCP',
  formalName: 'Suit',
  alternateName: '2 Piece Suit',
  bitmask: 5,
  image: null,
} as const
export const COAT_PANT_PANT = {
  abbreviation: 'CCPP',
  formalName: 'Suit',
  alternateName: '2 Piece Suit + Pant',
  bitmask: 21,
  image: null,
} as const
export const COAT_VEST_PANT = {
  abbreviation: 'CCVP',
  formalName: 'Suit',
  alternateName: '3 Piece Suit',
  bitmask: 7,
  image: null,
} as const
export const COAT_VEST_PANT_PANT = {
  abbreviation: 'CCVPP',
  formalName: 'Suit',
  alternateName: '3 Piece Suit + Pant',
  bitmask: 23,
  image: null,
} as const
export const CASUALJACKET = {
  abbreviation: 'CASUALJACKET',
  formalName: 'Casual Jacket',
  alternateName: null,
  bitmask: 1024,
  image: CasualJacketImage,
} as const

export const ALL_GARMENTS = [
  SHIRT,
  COAT,
  VEST,
  PANT,
  COAT_PANT,
  COAT_VEST_PANT,
  COAT_PANT_PANT,
  COAT_VEST_PANT_PANT,
  TOPCOAT,
  SHORT,
  BREEK,
  SWACKET,
  CASUALJACKET,
] as const

//* PRICES
export const PRICE_PROFILES = [
  { id: 1, title: 'USD', symbol: '$', rate: 1 },
  { id: 13, title: 'GBP', symbol: '£', rate: 0.625 },
  { id: 15, title: 'EURO', symbol: '€', rate: 0.7 },
  { id: 16, title: 'RMB', symbol: '¥', rate: 6.39 },
  { id: 17, title: 'AUD', symbol: 'AUD', rate: 1.17 },
  { id: 19, title: 'CAN', symbol: 'CAN$', rate: 1.15 },
]

//* MATERIALS
export const BUTTONS = { id: 'buttons', name: 'Buttons', image: ButtonImage }
export const FELT = { id: 'felt', name: 'Felt', image: FeltImage }
export const SUEDE = { id: 'microsuede', name: 'Microsuede', image: SuedeImage }
export const PIPING = { id: 'piping', name: 'Piping', image: PipingImage }
export const LABELS = { id: 'name-labels', name: 'Name Labels', image: NameLabelImage }
export const THREADS = { id: 'threads', name: 'Threads', image: ThreadImage }
export const TROUSER_TRIM = { id: 'trouser-trim', name: 'Trouser Trim', image: TrouserTrimImage }
export const ZIPPERS = { id: 'zippers', name: 'Zippers', image: ZipperImage }

export const ALL_MATERIALS = [BUTTONS, FELT, SUEDE, PIPING, LABELS, THREADS, TROUSER_TRIM, ZIPPERS]

//* DISTRIBUTION CENTERS
export const FACTORYID = { id: 5, name: 'iDesign', location: 'China', abbreviation: 'iD' }
export const FACTORYT2ID = { id: 6, name: 'Trisco', location: 'Indonesia', abbreviation: 'T2iD' }

export const DISTRIBUTION_CENTERS = [FACTORYID, FACTORYT2ID]
