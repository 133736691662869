import { useContext } from 'react'
import { GlobalContext } from 'components/AppProviders/global'
import { AuthContext } from 'components/AppProviders/auth'

function useGlobalState() {
  const globalContext = useContext(GlobalContext)
  const authContext = useContext(AuthContext)

  if (globalContext === null) {
    throw new Error('useGlobalState must be used within a GlobalProvider')
  }

  if (authContext === null) {
    throw new Error('useGlobalState must be used within a AuthProvider')
  }

  return { ...globalContext, ...authContext }
}

export { useGlobalState }
