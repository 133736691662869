import { Box, styled } from '@mui/material'
import type { BoxProps } from '@mui/material'

const CircularLabel = styled((props: BoxProps) => <Box {...props} />)(({ theme }) => ({
  borderRadius: 25.5,
  boxShadow: theme.elevation.card2,
  paddingTop: '1rem',
  paddingBottom: '1rem',
  ...theme.typography.body3,
  textTransform: 'capitalize',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    cursor: 'pointer',
    fontWeight: 600,
  },
}))

export { CircularLabel }
