import { useQuery } from 'react-query'
import type { Login } from 'types'
import API from './api'

async function getLogin(): Promise<Login> {
  try {
    const { data: user } = await API.get('login')

    return user
  } catch {
    return Promise.reject(new Error('There was an error - please try again.'))
  }
}

function useLogin() {
  return useQuery('login', getLogin)
}

export { useLogin }
