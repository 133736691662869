import { ExpandMore } from '@mui/icons-material'
import { Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, styled } from '@mui/material'
import type { AccordionProps, AccordionSummaryProps } from '@mui/material'

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters {...props} />)(({ theme }) => ({
  boxShadow: theme.shadows[0],
  '&:before': { display: 'none' },
  '& .MuiAccordionSummary-root.Mui-expanded': {
    border: theme.borders.selected,
    boxShadow: theme.elevation.accordian,
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMore sx={{ color: 'grey.700' }} />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  height: 76,
  border: theme.borders.n100,
}))

export { Accordion, AccordionSummary }
