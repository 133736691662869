import type { Theme } from '@mui/material'

export const getButton = (theme: Theme) => ({
  defaultProps: {
    variant: 'contained' as const,
  },
  styleOverrides: {
    root: {
      textTransform: 'none' as const,
      '&:disabled': {
        border: theme.borders.n100,
      },
    },
    sizeSmall: {
      height: 34,
      padding: '0.5rem 1rem',
      fontSize: '0.875rem',
      fontWeight: 600,
      // color: palette.common.white,
      // lineHeight: 0.875,
    },
    sizeMedium: {
      height: 43,
      padding: '0.75rem 1.5rem',
    },
    sizeLarge: {
      height: 67,
      padding: '1.5rem 3rem',
      fontSize: '1rem',
      fontWeight: 700,
      // color: palette.common.white,
      // lineHeight: 1,
    },
  },
  variants: [
    {
      props: { variant: 'contained', color: 'primary' } as const,
      style: {
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
          boxShadow: theme.elevation.button,
          color: theme.palette.common.white,
        },
      },
    },
    {
      props: { variant: 'contained', color: 'success' } as const,
      style: {
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: theme.palette.success.dark,
          boxShadow: theme.elevation.button,
          color: theme.palette.common.white,
        },
      },
    },
    {
      props: { variant: 'contained', color: 'neutral' } as const,
      style: {
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: theme.palette.neutral.dark,
          boxShadow: theme.elevation.button,
          color: theme.palette.common.white,
        },
      },
    },
    {
      props: { variant: 'outlined', color: 'primary' } as const,
      style: {
        border: theme.borders.primary,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          // '& span span svg': {
          // color: theme.palette.common.white,
          // },
        },
      },
    },
    {
      props: { variant: 'outlined', color: 'success' } as const,
      style: {
        border: theme.borders.success,
        '&:hover': {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.common.white,
        },
      },
    },
    {
      props: { variant: 'outlined', color: 'neutral' } as const,
      style: {
        border: theme.borders.n100,
        fontWeight: 500,
        '&:hover': {
          backgroundColor: theme.palette.neutral.main,
          color: theme.palette.common.white,
        },
      },
    },
    {
      props: { variant: 'text', color: 'primary' } as const,
      style: {
        fontWeight: 500,
        '&:hover': {
          backgroundColor: theme.palette.primary.extraLight,
          color: theme.palette.primary.dark,
          // & span span svg': {
          // color: theme.palette.primary.dark,
          // },
        },
      },
    },
    {
      props: { variant: 'text', color: 'success' } as const,
      style: {
        fontWeight: 500,
        '&:hover': {
          backgroundColor: theme.palette.success.extraLight,
          color: theme.palette.success.dark,
        },
      },
    },
    {
      props: { variant: 'text', color: 'neutral' } as const,
      style: {
        fontWeight: 500,
        '&:hover': {
          backgroundColor: theme.palette.neutral.extraLight,
          color: theme.palette.neutral.dark,
        },
      },
    },
    {
      props: { variant: 'rounded' } as const,
      style: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        borderRadius: 50,
        height: 32,
        width: 144,
        '&:hover': {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.primary.main,
        },
      },
    },
  ],
})

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true
  }
  interface ButtonPropsVariantOverrides {
    rounded: true
  }
}
