import axios from 'axios'
import type { AxiosHeaders } from 'axios'

const API = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}`,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
})

API.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('jwt')

    if (token && config.headers) {
      ;(config.headers as AxiosHeaders).set('Authorization', `Bearer ${token}`)
    } else {
      delete API.defaults.headers.common.Authorization
    }
    return config
  },

  error => Promise.reject(error),
)

export default API
